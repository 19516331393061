import moment from "moment";
import {noBasketRegions} from "./ogpoGetters";
import MD5 from 'crypto-js/md5'
const POLIS_URL = 'https://797.polisonline.kz'

const getOgpoAuthToken = (param) => {
  return 'Lce3tjz8j' + MD5(param + '797halyk').toString()
}
export const changePhoneNumber = ({commit}, newPhoneNumber) => {
  commit('SET_PHONE_NUMBER', newPhoneNumber)
}
export const setAmountLoaded = ({commit}, amountLoadedFlag) => {
  commit("SET_AMOUNT_LOADED", amountLoadedFlag)
}
export const changeSelectedProductId = ({commit}, payload) => {
  commit("SET_SELECTED_PRODUCT_ID", payload)
}
export const changeSelectedProductName = ({commit}, payload) => {
  commit("SET_SELECTED_PRODUCT_NAME", payload)
}
export const deletePerson = ({commit}, index) => {
  commit("REMOVE_CLIENT", index);
}
export const deleteCar = ({commit}, index) => {
  commit("REMOVE_CAR", index);
}
export const getPeopleByIin = async ({commit, state}, Iin) => {
  const {data} = await window.axios.post("https://797.polisonline.kz/api/GetByIin", {Iin, memberID: "641"}, {
    auth: {
      username: 'Otp',
      password: getOgpoAuthToken(Iin)
    }
  });

  const {statusCode, value} = data;

  if (statusCode) {
    throw {statusCode, value};
  }

  const isDuplicate = state.clients.some((p) => p.iin === Iin);

  if (isDuplicate) {
    throw {statusCode: "DUPLICATE_ITEM"}
  }

  commit("ADD_CLIENT", data);
}
export const getCarByLicensePlate = async ({commit, state}, GovNumber) => {
  const {data} = await window.axios.post("https://797.polisonline.kz/api/GetByGovNumber", {GovNumber}, {
    auth: {
      username: 'Otp',
      password: getOgpoAuthToken(GovNumber)
    }
  });

  const {statusCode, value} = data;

  if (statusCode) {
    throw {statusCode, value};
  }

  const isDuplicate = state.cars.some((item) => item.govNumber === GovNumber);

  if (isDuplicate) {
    throw {statusCode: "DUPLICATE_ITEM"}
  }

  commit("ADD_CAR", data);
}
export const getProducts = async ({getters, dispatch}) => {
  const {cars, clients, period, isStandard} = getters

  if (cars.length === 0 || clients.length === 0) {
    return;
  }

  const {productsRegion, carYearAndCarType, minRiskRating, noBasketRegions} = getters

  const hasOneProductRegion = productsRegion.length === 1;

  const postData = {
    code: hasOneProductRegion ? parseInt(productsRegion[0]) : 0,
    carYear: carYearAndCarType["minCarYear"],
    carType: carYearAndCarType["isCarLight"],
    iin: clients[0]["iin"],
    month: period,
    isStandard: isStandard,
    noBasketRegions: noBasketRegions,
    Bonusmalus: minRiskRating
  }

  await dispatch("setProducts", postData)

  /**
   * Переход из каких либо акции, но пока не нужный функционал
   */
  // if(hasOneProductRegion) {
  //     let chooseProductId = chooseProductId;
  //
  //     if (chooseProductId){
  //       let products = products
  //       let chooseProduct = products.some(v => v.mergeProduct.id == chooseProductId);
  //       if(chooseProduct){
  //         await commit("SET_SELECTED_PRODUCT_ID", chooseProductId)
  //       }
  //     }
  // }
}
export const setProducts = async ({commit, getters}, productData) => {
  let { friendBasket, phoneNumber } = getters

  let prodList = null;

  const postData = {
    Code: productData['code'],
    Type: 0,
    CarYear: productData["carYear"],
    CarType: productData["carType"],
    Iin: productData["iin"],
    Month: parseInt(productData["month"]),
    NoBasketRegion: productData["noBasketRegions"],
    isStandard: productData["isStandard"],
    agentID: productData['agentID'] || "0",
    memberID: productData['memberID'] || "0",
    friendBasket: friendBasket,
    PhoneNumber: phoneNumber,
    ProductList: prodList,
    Reinsurance: productData["Reinsurance"],
    Bonusmalus: productData["Bonusmalus"],
  }

  let {data} = await window.axios.post("https://797.polisonline.kz/api/GetProductByGovNumber", postData)

  const {selectProducts} = data;

  selectProducts.sort((a, b) => {
    return a.mergeProduct.sort - b.mergeProduct.sort;
  });

  await commit("SET_PRODUCTS", selectProducts);

  if (getters.selectedProductId !== 0) {
    var prodIds = selectProducts.map(
        (v) => v.mergeProduct.id
    );

    if (getters.selectedProductId === 15 && !prodIds.includes(getters.selectedProductId)) {
      var oilProduct = selectProducts.find((v) => v.mergeProduct.id == 13 || v.mergeProduct.id == 14 || v.mergeProduct.id == 15);
      if (oilProduct != null) {
        commit("SET_SELECTED_PRODUCT_ID", oilProduct["mergeProduct"]["id"]);
      }
    }
    if (getters.selectedProductId === 11 && !prodIds.includes(getters.selectedProductId)) {
      var bonusProduct = selectProducts.find(
          (v) => v["mergeProduct"]["id"] == 1 || v["mergeProduct"]["id"] == 2 || v["mergeProduct"]["id"] == 3 ||
              v["mergeProduct"]["id"] == 4 || v["mergeProduct"]["id"] == 11
      );
      if (bonusProduct != null) {
        commit("SET_SELECTED_PRODUCT_ID", bonusProduct["mergeProduct"]["id"]);
      }
    }

    if (getters.selectedProductId === 17 && !prodIds.includes(getters.selectedProductId)) {
      commit("SET_SELECTED_PRODUCT_ID", 18);
    }

    if (!prodIds.includes(getters.selectedProductId)) {
      commit("SET_SELECTED_PRODUCT_ID", 0);
    }
  }
}
export const isHaveBasketFriend = async ({commit, getters, dispatch}) => {
  const {isLoyalClient, carYearAndCarType, period, phoneNumber, minRiskRating, noBasketRegions } = getters

  let memberID = 0;

  try {
    let {data} = await window.axios.post(`${POLIS_URL}/api/isHaveBasketFriend`, {Phone: phoneNumber});

    const friendBasket = ((data === true || isLoyalClient) && minRiskRating > 5 && !noBasketRegions && carYearAndCarType["isCarLight"] && period === 12 && (memberID === 0 || memberID === "null" || memberID == null || memberID == 630 || memberID === 641));

    commit("SET_FRIEND_BASKET", friendBasket);

    if (friendBasket) {
      // TODO: показать модальное окно о лояльности клиента
    }
  } catch (e) {
    let stage = {
      name: 'Оформление полиса',
      id: '5',
      error: e
    };
    await dispatch('sendErrorsToRedmine', stage)
    alert(e)
  }
}
export const getCascoLite = async ({commit, getters, dispatch}) => {
  const {cars, clients, period, carYearAndCarType} = getters;

  if (cars.length === 0 || clients.length === 0) {
    return;
  }

  const postData = {carType: carYearAndCarType["isCarLight"], month: Number(period)}

  try {
    let {data} = await window.axios.post(`${POLIS_URL}/api/GetKaskoLite`, postData);

    if (data.length) {
      commit("SET_CASCO_LITE_OPTIONS", data)
    } else {
      commit("SET_CASCO_LITE_OPTIONS", null)
    }
  } catch (e) {
    let stage = {name: 'Оформление полиса', id: '5', error: e};
    await dispatch('sendErrorsToRedmine', stage)
  }
}
export const getFixSum = async ({commit, getters, dispatch}) => {
  const {cars, clients, beginDate, endDate} = getters;

  if (cars.length === 0 || clients.length === 0) {
    return;
  }

  const postData = {
    FixSumParameter: clients.map(client => {
      return {Iin: client.iin, discountBool: client.discountBool, experienceBool: client.experienceBool}
    }),
    GovNumbers: cars.map((car) => car.govNumber),
    BeginDate: new Date(beginDate).toISOString(),
    EndDate: new Date(endDate).toISOString(),
  }

  try {
    let {data} = await window.axios.post(`${POLIS_URL}/api/GetFixSum`, postData);

    if (data["statusCode"]) {
      this.$store.dispatch("SET_ADDSUMERROR", "Не удалось получить базовый расчет");
      let err = new Error(data["value"]);
    } else {
      commit("SET_ADDSUMERROR", "");
      commit("SET_BASESUMM", data);
    }
  } catch (err) {
    let stage = {
      name: 'Оформление полиса',
      id: '5',
      error: err
    };
    await dispatch('sendErrorsToRedmine', stage)
  }
}
export const getFixSumByProduct = async ({commit, getters}) => {
  commit("SET_CALCULATED_SUM", null);

  const {cars, clients, selectedProductId, friendBasket, period, baseSumm, cascoReward} = getters;

  if (cars.length === 0 || clients.length === 0) {
    return;
  }

  if (Number(baseSumm) === 0) {
    commit("SET_ADDSUMERROR", "Не удалось получить базовый расчет");
    return;
  }

  let discountBool = true;

  clients.forEach(client => discountBool = client.discountBool);
  if (cars.length > 1) {
    discountBool = false
  }

  let ProductID = 0;

  if (selectedProductId) {
    ProductID = selectedProductId;
  } else {
    commit("SET_SELECTED_PRODUCT_ID", 0);
    commit("SET_SELECTED_PRODUCT_NAME", "");
  }

  const postData = {
    discountBool, friendBasket, ProductID,
    Sum: String(baseSumm),
    Kasko: String(cascoReward),
    Month: Number(period)
  }

  try {
    const {data} = await window.axios.post(`${POLIS_URL}/api/GetFixSumByProduct`, postData)

    commit("SET_ADDSUMERROR", "");
    commit("SET_CALCULATED_SUM", data);

  } catch (err) {
    console.log(err)
  }
}
export const setTempPolicy = async ({commit, getters}) => {
  const requestObject = {
    guid: null,
    ClientID: [],
    CarID: [],
    BeginDate: "",
    PhoneNumber: "",
    PaymentRef: "",
    Iins: [],
    GovNumbers: [],
    TerritoryId: [],
    ProductID: null,
    FriendBasket: false,
    RequestId: String(getters.REQUEST_ID || 0),
    BaseSum: String(getters.baseSumm),
    Client: String(getters.COUNTERPARTY || ''),
    AdmitadUid: String(getters.ADMITAD_UID || '')
  }

  getters.clients.forEach((client, index) => {
    if (index === 0) {
      requestObject["MainClientID"] = client["getPeopleId"];
      requestObject["MainIin"] = client["iin"];
    } else {
      requestObject["ClientID"].push(client["getPeopleId"]);
      requestObject["Iins"].push(client["iin"]);
    }
  });

  getters.cars.forEach((car) => {
    requestObject["CarID"].push(car["carId"]);
    requestObject["GovNumbers"].push(car["govNumber"]);
    requestObject["TerritoryId"].push(car["territory"]);
  });

  requestObject["ProductID"] = Number(getters.selectedProductId)
  requestObject["FriendBasket"] = getters.friendBasket;
  requestObject["BeginDate"] = getters.beginDate;
  requestObject["EndDate"] = getters.endDate;
  requestObject["verifyType"] = getters.allVerified;
  requestObject["MemberID"] = "641"

  requestObject.PhoneNumber = `7${getters.phoneNumber}`;

  if (getters.calculatedSum?.kasko) {
    requestObject.kasko = String(getters.calculatedSum?.kasko);
  }

  let {data} = await window.axios.post(`${POLIS_URL}/api/SetTempPolicy`, requestObject);

  commit("SET_INVOICEID", data);
}
export const sumVerification = async ({getters}) => {
  const dataObj = {
    CarID: getters.cars.map((car) => car.carId),
    ClientID: getters.clients.map((client) => client.getPeopleId),
    BeginDate: getters.beginDate,
    EndDate: getters.endDate,
  };

  const {data} = await window.axios.post(`${POLIS_URL}/api/GetVerifyFixSum`, dataObj);

  if (data?.statusCode !== 200) {
    throw {message: data?.value || 'Произошла ошибка при сверке суммы. Повторите попытку позднее'}
  }

  const verifiedFixedSum = parseFloat(data.value);

  if (getters.calculatedSum["discountSum"] !== 0) {
    if (verifiedFixedSum != parseFloat(getters.calculatedSum["discountSum"])) {
      getters.client.forEach((client) => {
        client["checked"] = false;
      });
      getters.cars.forEach((car) => {
        car["checked"] = false;
      });

      throw {
        message: `После подтверждения документов мы произвели перерасчет и обнаружили, что указанные вами данные о стаже/инвалидности не верные. \nСтоимость страховки до перерасчета без учета скидок=
                ${getters.calculatedSum["discountSum"]}\nСтоимость страховки после перерасчета без учета скидок =${data.value}`
      }
    }
  } else {
    if (verifiedFixedSum != parseFloat(getters.calculatedSum["sum"])) {
      getters.clients.forEach((client) => {
        client["checked"] = false;
      });
      getters.cars.forEach((car) => {
        car["checked"] = false;
      });

      throw {
        message: `После подтверждения документов мы произвели перерасчет и обнаружили, что указанные вами данные о стаже/инвалидности не верные. \nСтоимость страховки до перерасчета без учета скидок=
                ${getters.calculatedSum["discountSum"]}\nСтоимость страховки после перерасчета без учета скидок = ${data.value}`
      }
    }
  }
}
export const sendOTP = async ({commit, getters}) => {
  const {data} = await window.axios.get(`${POLIS_URL}/otp`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      phone: `7${getters.phoneNumber}`,
    },
    auth: {
      username: 'Otp',
      password: getOgpoAuthToken(`7${getters.phoneNumber}`)
    }
  });

  commit("SET_OTPKEY", data?.key);
}
export const verifyOTP = async ({getters, dispatch, commit}, code) => {
  try {
    await window.axios.post(`${POLIS_URL}/otp`,
        {otp: code, phone: `7${getters.phoneNumber}`, key: getters.otpKey},
        {headers: {"Content-Type": "application/json"}, auth: {username: 'Otp', password: getOgpoAuthToken(`7${getters.phoneNumber}`) }}
    );
    commit('SET_OTPCODE', true);
  } catch (err) {
    let stage = {
      name: 'Подтверждение по смс',
      id: '7',
      error: err
    };
    await dispatch('sendErrorsToRedmine', stage)
    throw {code: err?.response?.status}
  }

}
export const sendErrorsToRedmine = async ({state, getters}, stage) => {
  console.log(stage);
  // await window.axios.post('https://797.polisonline.kz/log/notifications/saveToDb',
  //     {
  //       chosen_product: state.selectedProductId,
  //       error_message: stage.error?.message || stage.error,
  //       error_time: new Date(),
  //       error_type: stage.error?.message || stage.error,
  //       stage: stage.name,
  //       stage_id: stage.id,
  //       sum: getters.calculatedSum?.fixedSum || null,
  //       type: "error",
  //       url: window.location.href,
  //       user_data: {
  //         car_numbers: state.cars.map(e => e.govNumber),
  //         iins: state.clients.map(e => e.iin),
  //         phone: state.phoneNumber
  //       },
  //     }
  // );
}
export const saveNotificationsToRedmine = async ({state, getters}, stage) => {
  // await window.axios.post('https://797.polisonline.kz/log/notifications/saveToDb',
  //     {
  //       chosen_product: state.selectedProductId,
  //       error_message: '',
  //       error_time: new Date(),
  //       error_type: "Клиент ушел с сайта",
  //       stage: stage.name,
  //       stage_id: stage.id,
  //       sum: getters.calculatedSum?.fixedSum || null,
  //       type: "notification",
  //       url: window.location.href,
  //       user_data: {
  //         car_numbers: state.cars.map(e => e.govNumber),
  //         iins: state.clients.map(e => e.iin),
  //         phone: state.phoneNumber
  //       },
  //     }
  // );
}
export const checkClientsDoc = async ({getters, commit}) => {
  const {clients} = getters;

  if (clients.length === 0) {
    return false
  }

  for (let i = 0; i < clients.length; i++) {
    const client = clients[i]

    client.clientDocuments = client.clientDocuments.map(doc => {
      doc.docIssueDate = moment(doc?.docIssueDate).format("YYYY-MM-DDT00:00:00.000") + "Z";
      return doc;
    })

    if (clients[0].iin === client.iin) {
      client.email = getters.email;
    }

    const {data} = await window.axios.post(`${POLIS_URL}/api/SetNewClient`, client, {
      auth: {
        username: 'Otp',
        password: getOgpoAuthToken(client.iin)
      }
    });

    const {statusCode, value, verifyType, getPeopleId} = data;

    if (statusCode) {
      client.checked = false;
      client.checkMessage = value;
      commit("REPLACE_CLIENT", {index: i, client})

      throw { message: value }
    } else {
      client.getPeopleId = getPeopleId;
      client.checked = true;
      client.verifyType = verifyType;

      if (verifyType !== 1) {
        // this.$store.dispatch("SET_DOCS_OFFLINE", false);
      }

      commit("REPLACE_CLIENT", {index: i, client})
    }
  }
}

export const fetchAvailableCars =  async ({ commit, dispatch }, iin) => {
  const {data} = await window.axios.get(`${POLIS_URL}/api/GetPolicyInfo?iin=${iin}`);

  if (data?.regNumber) {
    let text = data.regNumber;
    let numbers = text.split(",");
    commit("SET_AVAILABLE_CARS", numbers)
  }
}