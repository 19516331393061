export default {
    namespaced:true,
    state: {
        form: null
    },
    mutations: {
        "FILL_FORM"(state, payload) {
            state.form = payload;
        }
    },
    actions: {

    },
    getters: {
        finalFormValue(state) {
            return state.form
        }
    }
}
