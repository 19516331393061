import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const Agreements = () => import("../views/NavigationPages/Agreements")
const ArchiveAgreements = () => import("../views/NavigationPages/ArchiveAgreements");
const WelcomeRedesign = () => import("../views/NavigationPages/WelcomeRedesign")
const ChatNavigation = () => import("../views/NavigationPages/ChatNavigation")
const SosNavigation = () => import("../views/SosNavigation");
const Maintenance = () => import('../views/Maintenance')
const MedCards = () => import('../views/MedCards')
const InsuranceTypes = () => import("../views/InsuranceTypes");
const OgpoFormConfirm = () => import("../views/OgpoFormConfirm");
const OgpoOtpVerification = () => import("../views/OgpoOtpVerification");
const AddNewCreditCard = () => import("../views/AddNewCreditCard");
const SendFeedback = () => import("../views/SendFeedback");
const Menu = () => import("../views/NavigationPages/Menu");
const SendSingleFeedback = () => import("../views/ChatFeedback");
const AboutUs = () => import("../views/AboutUs");
const Notifications = () => import("../views/Notifications");

const SignIn = () => import("../views/Auth/SignIn");
const SignUp = () => import("../views/Auth/SignUp");
const LanguageChange = () => import("../views/LanguageChange");
const Promotions = () => import("../views/Promotions");
const Branches = () => import("../views/Branches");
const DownloadAgreement = () => import("../views/DownloadAgreement");
const OgpoReinsuranceConfirm = () => import("../views/OgpoReinsuranceConfirm");
const OgpoReinsuranceOtpVerify = () => import("../views/OgpoReinsuranceOtpVerify");
const ResetPassword = () => import("../views/Auth/ResetPassword");
const AboutProduct = () => import("../views/AboutProduct");
const OgpoExtensionConfirm = () => import("../views/OgpoExtensionConfirm");
const OgpoExtensionVerify = () => import("../views/OgpoExtensionVerify");
const PaymentNumberContract = () => import("../views/Payment/PaymentNumberContract");
const PaymentTreaty = () => import("../views/Payment/PaymentTreaty");

import store from "../store"
/**
 * SOS navigation
 * */
const SosInstruction = () => import("../views/SosInstruction");
const SosForm = () => import("../views/SosForm");

/**
 * ChatAndBot Navigation
 * */
const Chat = () => import("../views/Chat/Chat");
const ChatBot = () => import("../views/Chat/ChatBot");


/**
 * Agreements Navigation
 * */
const AgreementItem = () => import("../views/Agreements/AgreementItem");
const AgreementItemPolicy = () => import("../views/Agreements/AgreementItemPolicy");
const ChangeTariff = () => import("../views/Agreements/ChangeTariff");
const AgreementVerification = () => import("../views/Agreements/AgreementVerification");
const AgreementItemLife = () => import("../views/Agreements/AgreementItemLife");
const AgreementPdf = () => import("../views/Agreements/Pdf")
/**
 * Insurance products Navigation
 * */
const PolicyForm = () => import("../views/PolicyForm");
const OgpoReinsurance = () => import("../views/OgpoReinsurance");
const OgpoExtension = () => import("../views/OgpoExtension");
const GetInfo = () => import("../views/GetInfo");
const InsuresProductsCheckout = () => import("../views/InsuresProducts/InsuresProductsCheckout");
const PaymentSuccess = () => import("../views/InsuresProducts/Payment/PaymentSuccess");
const PaymentFail = () => import("../views/InsuresProducts/Payment/PaymentFail");

const routes = [
  // INSURANCE FORM NAVIGATIONS
  {
    path: '/product-:id/:form_component',
    name: 'insurance.products',
    component: PolicyForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ogpo-reinsurance/:id',
    name: 'OgpoReinsurance',
    component: OgpoReinsurance,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ogpo-extension/:id',
    name: 'OgpoExtension',
    component: OgpoExtension,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/insurance/products/:id/get-info/:typeOfInput',
    name: 'insurance.products.get.info',
    component: GetInfo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/insurance/products/:id/checkout',
    name: 'insurance.products.checkout',
    component: InsuresProductsCheckout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/insurance-products/:id/payment/success',
    name: 'insurance.products.payment.success',
    component: PaymentSuccess,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/insurance-products/:id/payment/fail',
    name: 'insurance.products.payment.fail',
    component: PaymentFail,
    meta: {
      requiresAuth: true,
    },
  },
  // END INSURANCE FORM NAVIGATIONS

  // Agreements
  {
    path: '/agreements/product-:id/:agreement/:active',
    name: 'agreements.item',
    component: AgreementItem,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/agreements/:agreement',
    name: 'agreements.policy',
    component: AgreementItemPolicy,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/agreements/:agreement/pdf',
    name: 'agreements.pdf',
    component: AgreementPdf,
    meta: {
      // requiresAuth: true,
    },
  },
  {
    path: '/change-tariff/:agreement',
    name: 'change.tariff',
    component: ChangeTariff,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/agreement-verify',
    name: 'agreement.verify',
    component: AgreementVerification,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/agreement/life/:id',
    name: 'agreement.item.life',
    component: AgreementItemLife,
    meta: {
      requiresAuth: true,
    },
  },
  // Agreements item life
  // Med cards
  {
    path: '/med-cards',
    name: 'med-cards',
    component: MedCards,
    meta: {
      requiresAuth: true
    }
  },
  // Chat navigation
  {
    path: '/chat-navigation',
    name: 'chat.navigation',
    component: ChatNavigation
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/bot/:username',
    name: 'bot',
    component: ChatBot,
    meta: {
      requiresAuth: true,
    },
  },
  // Chat navigation end

  // SOS navigation
  {
    path: '/sos-navigation',
    name: 'sos.navigation',
    component: SosNavigation,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/sos-instruction/:id',
    name: 'sos.instruction',
    component: SosInstruction,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/sos-form/:id',
    name: 'sos.form',
    component: SosForm,
    meta: {
      requiresAuth: true,
    },
  },
  // sos navigation end

  {
    path: "/maintenance",
    name: "maintenance",
    component: Maintenance
  },
  {
    path: "/register",
    name: "register",
    component: SignUp,
  },
  {
    path: "/login",
    name: "login",
    component: SignIn,
  },
  {
    path: "/reset-password",
    name: 'reset-password',
    component: ResetPassword
  },
  {
    path: "/",
    name: "Welcome",
    component: WelcomeRedesign,
  },
  {
    path: "/agreements",
    name: "Agreements",
    component: Agreements,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/archive-agreements",
    name: "archive-agreements",
    component: ArchiveAgreements
  },
  {
    path: "/insurance-types/:id/about-product",
    name: "AboutProduct",
    component: AboutProduct
  },
  {
    path: "/ogpo-confirm",
    name: "ogpo.form.confirm",
    component: OgpoFormConfirm,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/ogpo-verify",
    name: "ogpo.otp.verify",
    component: OgpoOtpVerification,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/ogpo-reinsurance-confirm",
    name: "ogpo-reinsurance.form.confirm",
    component: OgpoReinsuranceConfirm,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/ogpo-reinsurance-verify",
    name: "ogpo-reinsurance.otp.verify",
    component: OgpoReinsuranceOtpVerify,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/ogpo-extension-confirm",
    name: "ogpo-extension.form.confirm",
    component: OgpoExtensionConfirm,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/ogpo-extension-verify",
    name: "ogpo-extension.otp.verify",
    component: OgpoExtensionVerify,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/menu",
    name: "menu",
    component: Menu,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/promotions",
    name: "promotions",
    component: Promotions,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/language",
    name: "language",
    component: LanguageChange,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/insurance-types/:id",
    name: "insurance.types",
    component: InsuranceTypes
  },
  {
    path: "/add-new-credit-card",
    name: "add.new.credit.card",
    component: AddNewCreditCard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/send-feedback",
    name: "send.feedback",
    component: SendFeedback,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/send-single-feedback",
    name: "send.single.feedback",
    component: SendSingleFeedback,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about-us",
    name: "about.us",
    component: AboutUs,
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/branches",
    name: "branches",
    component: Branches,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/download/:id",
    name: "DownloadAgreement",
    component: DownloadAgreement,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/payment-number-contract",
    name: "payment-number-contract",
    component: PaymentNumberContract,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/payment-treaty/:id",
    name: "payment-treaty",
    component: PaymentTreaty,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

router.beforeEach((to, from, next) => {
  alert(JSON.stringify(from))
  if(to.query.lang) {
    store.dispatch('getLanguage', to.query.lang)
  }

  if(to.query.avc) {
    store.commit('setAVN', Number(to.query.avc))
  }

  if(to.query.os) {
    store.commit('setOs', to.query.os)
  }

  if (to.query.caskey) {
    return store.dispatch('signInByCaskey', to.query.caskey)
        .then(() => window.location = window.location.pathname)
        .catch(() => {
          next("/login")
        })
  } else if(to.query.token) {
    return store.dispatch('signInByToken', to.query.token)
        .then(() => next(to.path) )
        .catch((e) => {
          console.log('error', e);
          next("/login")
        })
  } else {
    next();
  }
})

export default router;
